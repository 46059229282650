<template>
    <div>
        <div class="p-grid p-mx-0">
            <div class="p-col-12 p-md-12 p-d-flex p-ai-center p-jc-start p-pl-0">
                <div class="p-card-body id-item p-mr-2 p-d-flex p-ai-center p-py-2 p-px-3">
                    <div class="p-card-subtitle p-mb-0">
                        ID: <span class="black">{{ formData.orgId }}</span>
                    </div>
                </div>
                <div class="p-card-body id-item p-mr-2 p-d-flex p-ai-center p-py-2 p-px-3">
                    <div class="p-card-subtitle p-mb-0">
                        Дата создания: <span class="black"> {{ formData.dateCreated | date }}</span>
                    </div>
                </div>
                <div class="p-card-body id-item p-mr-2 p-d-flex p-ai-center p-py-2 p-px-3">
                    <div class="p-card-subtitle p-mb-0">
                        Дата обновления: <span class="black"> {{ formData.dateUpdated | date }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-form p-p-1 p-card p-mt-1">
            <div class="p-fluid p-formgrid p-grid p-mx-0 p-my-2">
                <div class="p-col-12">
                    <div class="p-formgroup-inline">
                        <!-- <i v-if="formData.isHeadOrganization.changed" class="pi pi-pencil p-mx-1" /> -->
                        <div class="p-field-radiobutton">
                            <RadioButton id="isHeadOrganizationTrue" name="headOrg" :value="true" v-model="formData.isHeadOrganization.value" :disabled="true" />

                            <label for="isHeadOrganizationTrue">Головная</label>
                        </div>
                        <div class="p-field-radiobutton">
                            <RadioButton id="isHeadOrganizationFalse" name="headOrg" :value="false" v-model="formData.isHeadOrganization.value" :disabled="true" />
                            <label for="isHeadOrganizationFalse">Филиал</label>
                        </div>
                        <div class="p-field-checkbox p-pl-1 p-pb-0 p-mb-0">
                            <Checkbox @focus="makeTouched('active')" @update:modelValue="makeChanged('active')" :binary="true" id="active" v-model="formData.active" :disabled="true" />
                            <label for="active">Активный</label>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-p-0 p-md-6">
                    <div class="p-field p-col-12">
                        <!-- <i v-if="formData.headOrg.changed" class="pi pi-pencil p-mr-1" /> -->
                        <label for="headOrg">Головная организация</label>
                        <input-text id="orgTypes" v-model="formData.headOrg.value" :disabled="true" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="orgTypes">Тип организации</label>
                        <input-text id="orgTypes" v-model="formData.type.attributes.name" :disabled="true" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="subordination">Подчинение</label>
                        <input-text id="orgTypes" v-model="formData.subordinateTo.attributes.name" :disabled="true" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="location">Расположение</label>
                        <input-text id="orgTypes" v-model="formData.location.attributes.name" :disabled="true" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="fullName">Полное наименование</label>
                        <Textarea id="fullName" v-model="formData.fullName" placeholder="Введите полное наименование" :autoResize="true" rows="3" cols="30" :disabled="true" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="shortName">Краткое наименование</label>
                        <input-text id="shortName" v-model="formData.shortName" placeholder="Введите краткое наименование" :disabled="true" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="abbreviationName">Аббревиатура</label>
                        <input-text id="abbreviationName" v-model="formData.abbreviationName" placeholder="Введите аббревиатуру" :disabled="true" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="legalAddress">Почтовый адрес</label>
                        <input-text id="legalAddress" v-model="formData.legalAddress" placeholder="Введите почтовый адресс" :disabled="true" />
                    </div>
                </div>
                <div class="p-col-12 p-p-0 p-md-6">
                    <div class="p-field p-col-12">
                        <label for="inn">ИНН</label>
                        <input-text :disabled="true" id="inn" v-model="formData.inn" placeholder="ИНН" />
                    </div>
                    <div class="p-field p-col-12">
                        <label>КПП</label>
                        <input-text :disabled="true" id="legalKpp" v-model="formData.legalKpp" placeholder="КПП" />
                    </div>
                    <div class="p-field p-col-12">
                        <label>ОГРН</label>
                        <input-text :disabled="true" id="legalOgrn" v-model="formData.legalOgrn" placeholder="ОГРН" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="legalOkpo">ОКПО</label>
                        <input-text :disabled="true" id="legalOkpo" v-model="formData.legalOkpo" placeholder="ОКПО" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="legalOkved">ОКВЭД</label>
                        <input-text :disabled="true" id="legalOkved" v-model="formData.legalOkved" placeholder="ОКВЭД" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="legalOkdp">ОКПД</label>
                        <input-text :disabled="true" id="legalOkdp" v-model="formData.legalOkdp" placeholder="ОКПД" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="legalOkonh">ОКОНХ</label>
                        <input-text :disabled="true" id="legalOkonh" v-model="formData.legalOkonh" placeholder="ОКОНХ" />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="legalOkato">ОКАТО</label>
                        <input-text :disabled="true" id="legalOkato" v-model="formData.legalOkato" placeholder="ОКАТО " />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="legalOkogu">ОКОГУ</label>
                        <input-text :disabled="true" id="legalOkogu" v-model="formData.legalOkogu" placeholder="ОКОГУ" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getOrganizationById } from '@/api/organization';
import { ORG_FIELDS } from '@/constants/organizations';

export default {
    name: 'userOrganization',
    emits: ['loadingChange'],
    data: () => ({
        leaveRoute: '/',
        leaveDialog: false,
        formData: {
            orgId: null,
            dateCreated: null,
            dateUpdated: null,
            isHeadOrganization: { value: false, touched: false, changed: false },
            headOrg: { value: null, touched: false, changed: false, id: '' },
            orgType: { value: null, touched: false, changed: false },
            location: { value: null, touched: false, changed: false },
            subordination: { value: null, touched: false, changed: false },
            fullName: { value: '', touched: false, changed: false },
            shortName: { value: '', touched: false, changed: false },
            abbreviationName: { value: '', touched: false, changed: false },
            legalAddress: { value: '', touched: false, changed: false },
            active: { value: false, touched: false, changed: false },
            inn: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalKpp: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalOgrn: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalOkpo: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalOkved: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalOkdp: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalOkonh: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalOkato: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
            legalOkogu: {
                value: '',
                touched: false,
                changed: false,
                disabled: false,
            },
        },
        filteredOrganizations: [],
        filteredLocations: [],
        filteredSubordination: [],
        filteredOrgTypes: [],
        orgInfo: null,
    }),
    methods: {
        async getCurrentOrganizationById() {
            const {
                data: { attributes: orgData, relationships }, included: included
            } = await getOrganizationById(this.organizationId);
            ORG_FIELDS.forEach((key) => {
                this.formData[key] = orgData[key] || '';
            });

            Object.keys(relationships).forEach((prop) => {
                const propData = relationships[prop].data;
                if (propData && !Array.isArray(propData)) {
                    this.formData[prop] = included.find(item => item.id === propData.id);
                }
            });

            if ('parent' in relationships) {
                this.formData.isHeadOrganization.value = relationships.parent.data !== null ? false : true;

                await this.getHeadOrganization(relationships.parent.data !== null ? relationships.parent.data.id : null);
            }
            this.formData.orgId = orgData.incrementId;
            this.formData.dateCreated = orgData.createdAt;
            this.formData.dateUpdated = orgData.updatedAt;
        },

        async getHeadOrganization(headOrgId) {
            if (!headOrgId) return;
            const headOrgInfo = await getOrganizationById(headOrgId);
            this.formData.headOrg.value = headOrgInfo.data.attributes.fullName;
        },
    },
    computed: {
        ...mapGetters('auth', ['currentUser']),
        organizationId() {
            return this.currentUser.orgId;
        },
        isHeadOrganization() {
            return this.formData.isHeadOrganization.value;
        },
    },
    async mounted() {
        await this.getCurrentOrganizationById();
        this.$emit('loadingChange');
    },
};
</script>

<style scoped lang="scss">
.my-form {
    background-color: #f8f9fa;
}
label {
    display: block;
    padding-left: 0.1rem;
    padding-bottom: 4px;
    margin-bottom: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #272727;
}
.p-field-checkbox,
.p-field-radiobutton {
    label {
        padding-bottom: 0.1rem;
    }
}
.black {
    color: black;
}
.id-item {
    background: #f8f9fa;
    border-radius: 4px;
}
.p-inputtext {
    padding: 8px 12px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}
::v-deep .p-autocomplete {
    .p-autocomplete-input {
        border-right: none;
    }
    .p-autocomplete-dropdown {
        background: transparent;
        color: #8794a3;
        width: 2.357rem;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #ffffff;
        border: none;
        border-top: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
    }
}
</style>
